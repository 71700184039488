<template>
    <div v-if="llamadaActivado"  style="display: grid; grid-template-columns:4fr 3fr 1fr; gap: 25px;">
        <usuario-resumen-estadisticas-siniestros ref="siniestros" :datosestadisticas="datosestadisticas" />
        <usuario-resumen-estadisticas-llamadas ref="llamadas" :datosestadisticas="datosestadisticas" v-if="llamadaActivado"/>
        <div class="totales" style="display: grid; grid-template-rows: 1fr 1fr 1fr; gap: 20px; margin-bottom: 1rem;">
            <div>
                <p>Visitas revisadas</p>
                <p>{{ revisadas }}</p>
            </div>
            <div>
                <p>Exp. finalizados</p>
                <p>{{ finalizados }}</p>
            </div>
            <div>
                <p>Facturas y albaranes</p>
                <p>{{ facturaciones }}</p>
            </div>
        </div>
    </div>
    <div v-else style="display: grid; grid-template-columns:4fr  2fr; gap: 25px;">
        <usuario-resumen-estadisticas-siniestros ref="siniestros" :datosestadisticas="datosestadisticas" />
        <div class="totales" style="display: grid; grid-template-rows: 1fr 1fr 1fr; gap: 20px; margin-bottom: 1rem;">
            <div>
                <p>Visitas revisadas</p>
                <p>{{ revisadas }}</p>
            </div>
            <div>
                <p>Exp. finalizados</p>
                <p>{{ finalizados }}</p>
            </div>
            <div>
                <p>Facturas y albaranes</p>
                <p>{{ facturaciones }}</p>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-7">
            <div class="row">
                <div class="col-md-6">
                    <usuario-resumen-estadisticas-facturacion ref="facturacion" :datosestadisticas="datosestadisticas" />
                </div>
                <div class="col-md-6" style="margin-bottom: 16px;">
                    <div class="card" style="height: 100%;">
                        <div class="card-header">
                            <h3 class="card-title">
                                <i class="far fa-calendar-alt mr-1"></i>
                                Alertas de visitas
                            </h3>
                        </div>
                        <div class="card-body">                            
                            <div class="chart" style="position: relative; ">
                                
                                <a @click="cargarExpedienteAvisos(aviso.titulo)" href="#" class="dropdown-item" v-for="aviso in avisosVisitas" :key="aviso.titulo" :style="aviso.style"  >
                                    <div data-toggle="tooltip" data-placement="top"  >
                                        <i class="fas fa-angle-right ml-2 mr-1" :title="aviso.texto"></i> {{aviso.texto}}    
                                        <span class="badge bg-danger float-right">{{aviso.numero}}</span>   
                                    </div>
                                </a> 
                                
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
            <usuario-resumen-estadisticas-acciones ref="acciones" :datosestadisticas="datosestadisticas"/>
        </div>
        <div class="col-md-5">
            <div class="row">
                <div class="col-md-6">
                    <usuario-resumen-estadisticas-incidencias ref="incidencias" :datosestadisticas="datosestadisticas" />                
                </div>
                <div class="col-md-6" style="margin-bottom: 35px;">
                        <div class="card" style="height: 100%;">
                            <div class="card-header">
                                <h3 class="card-title">
                                    <i class="far fa-smile mr-1"></i>
                                    Satisfacción
                                </h3>
                                <i class="fas fa-info" style="float:right" @click="toggle2($event)"></i>
                            </div>
                            <div class="card-body">                            
                                <div class="chart" style="position: relative; ">
                                    <a @click="cargarExpedienteSatis(satis)" href="#" class="dropdown-item" v-for="satis in satisfacciones" :key="satis.valor">
                                        <div data-toggle="tooltip" data-placement="top"  >
                                            <i :class="satis.class"></i> {{satis.titulo}}    
                                            <span :class="satis.bg">{{satis.total}}</span>     
                                        </div>
                                    </a> 
                                    
                                </div>
                                <OverlayPanel ref="info2">
                                    <h4 style="text-align:center">Satisfacción</h4>
                                    <p>Número de expedientes por nota de satisfacción</p>

                                </OverlayPanel>
                            </div>
                        </div>
                    </div>
            </div>
            <usuario-resumen-estadisticas-flota ref="flota" :datosestadisticas="datosestadisticas" v-if="flotaActivo"/>
        </div>
    </div>
</template> 
<script>
import UsuarioResumenEstadisticasFlota from './secciones/UsuarioResumenEstadisticasFlota.vue';
import UsuarioResumenEstadisticasSiniestros from './secciones/UsuarioResumenEstadisticasSiniestros.vue';
import UsuarioResumenEstadisticasFacturacion from './secciones/UsuarioResumenEstadisticasFacturacion.vue';
import UsuarioResumenEstadisticasLlamadas from './secciones/UsuarioResumenEstadisticasLlamadas.vue';
import UsuarioResumenEstadisticasIncidencias from './secciones/UsuarioResumenEstadisticasIncidencias.vue';
import UsuarioResumenEstadisticasAcciones from './secciones/UsuarioResumenEstadisticasAcciones.vue';
import OverlayPanel from 'primevue/overlaypanel';
import { PwgsApi } from '../../../../services/PwgsApi'
export default ({
    data() {
     return {
        datosestadisticas: [],
        avisosVisitas:[],
        satisfacciones: [],
        flotaActivo:false,
        llamadaActivado: false,
        revisadas: 0,
        finalizados: 0,
        facturaciones:0,
    }  
    },
    components: {
        'usuario-resumen-estadisticas-siniestros': UsuarioResumenEstadisticasSiniestros,
        'usuario-resumen-estadisticas-facturacion': UsuarioResumenEstadisticasFacturacion,
        'usuario-resumen-estadisticas-llamadas': UsuarioResumenEstadisticasLlamadas,
        'usuario-resumen-estadisticas-incidencias': UsuarioResumenEstadisticasIncidencias,
        'usuario-resumen-estadisticas-acciones': UsuarioResumenEstadisticasAcciones,
        'usuario-resumen-estadisticas-flota':UsuarioResumenEstadisticasFlota,
        OverlayPanel,
    },
    methods: {
        cargarExpedienteAvisos: function(tipo){
            //alert(tipo);
            console.log("tipo", tipo);
            localStorage.setItem("tipobuscador", tipo);
            this.$router.push({ name: 'Escritorio', params: { tipo_buscador: tipo } })
            
           // this.$emit('evento_expediente_avisos_tipo', tipo); //Es llamado a ./Escritorio.vue
        },
        cargarExpedienteSatis: function(tipo){
            //alert(tipo);
            localStorage.setItem("tipobuscador", tipo.valor);
            this.$router.push({ name: 'Escritorio', params: { tipo_buscador: tipo } })
            
           // this.$emit('evento_expediente_avisos_tipo', tipo); //Es llamado a ./Escritorio.vue
        },
        avisar(tramitador){
            this.cargarestadisticas(tramitador);
            this.avisosysatis(tramitador);
            this.$refs.acciones.cargardatos(tramitador);
        },
        async avisosysatis(tramitador){
            this.satisfacciones = [];
            this.avisosVisitas = [];
            const api = new PwgsApi;
            var rr = '';
            var st = '';
            if(tramitador && tramitador!='todos'){
                rr  = await api.post('avisos/visitas', {id_usuario: tramitador});
                st = await api.post('avisos/satisfaccion', {id_usuario: tramitador});
                if(!st){
                    st = [];
                }
            }else{
                rr  = await api.post('avisos/visitas');
                st = await api.post('avisos/satisfaccion');

            }
            const avisos = rr.datos;
            var st0_encontrado = st.find(s=> s.satisfaccion_ia_servicio==0);
            var n0 = '';
            if(st0_encontrado){
                n0 = {titulo: '0: Neutro',bg:"badge bg-secondary float-right" , class: " fas fa-angle-right ml-2 mr-1", valor: 0, total:st0_encontrado.total};
                    this.satisfacciones.push(n0);
            }else{
                n0 = {titulo: '0: Neutro', bg:"badge bg-secondary float-right" , class: " fas fa-angle-right ml-2 mr-1", valor: 0, total:0};
                    this.satisfacciones.push(n0);
            }
            var st1_encontrado = st.find(s=> s.satisfaccion_ia_servicio==1);
            var n1='';
            if(st1_encontrado){
                n1 = {titulo: '1: Muy insatisfecho',bg:"badge bg-danger float-right" , class: " fas fa-angle-right ml-2 mr-1", valor: 1, total:st1_encontrado.total};
                    this.satisfacciones.push(n1);
            }else{
                n1 = {titulo: '1: Muy insatisfecho', bg:"badge bg-danger float-right" , class: " fas fa-angle-right ml-2 mr-1", valor: 1, total:0};
                    this.satisfacciones.push(n1);
            }
            var st2_encontrado = st.find(s=> s.satisfaccion_ia_servicio==2);
            var n2 = '';
            if(st2_encontrado){
                n2 = {titulo: '2: Poco satisfecho',bg:"badge bg-warning float-right" , class: " fas fa-angle-right ml-2 mr-1", valor: 2, total:st2_encontrado.total};
                    this.satisfacciones.push(n2);
            }else{
                n2 = {titulo: '2: Poco satisfecho', bg:"badge bg-warning float-right" , class: " fas fa-angle-right ml-2 mr-1", valor:2, total:0};
                this.satisfacciones.push(n2);
            }
            var st3_encontrado = st.find(s=> s.satisfaccion_ia_servicio==3);
            var n3 = '';
            if(st3_encontrado){
                n3 = {titulo: '3: Satisfecho',bg:"badge bg-info float-right" , class: " fas fa-angle-right ml-2 mr-1", valor: 3, total:st3_encontrado.total};
                    this.satisfacciones.push(n3);
            }else{
                n3 = {titulo: '3: Satisfecho', bg:"badge bg-info float-right" , class: " fas fa-angle-right ml-2 mr-1", valor: 3, total:0};
                    this.satisfacciones.push(n3);
            }
            var st4_encontrado = st.find(s=> s.satisfaccion_ia_servicio==4);
            var n4 = '';
            if(st4_encontrado){
                n4 = {titulo: '4: Muy satisfecho',bg:"badge bg-success float-right" , class: " fas fa-angle-right ml-2 mr-1", valor: 4, total:st4_encontrado.total};
                    this.satisfacciones.push(n4);
            }else{
                n4 = {titulo: '4: Muy satisfecho', bg:"badge bg-success float-right" , class: " fas fa-angle-right ml-2 mr-1", valor: 4, total:0};
                    this.satisfacciones.push(n4);
            }
            console.log('sats',this.satisfacciones);

            console.log('avisosVisitas',avisos)
            if(avisos.no_en_domicilio){
                this.avisosVisitas.push({'titulo':'no_en_domicilio','texto':'No en domicilio', 'numero':avisos.no_en_domicilio});
            }else{
                this.avisosVisitas.push({'titulo':'no_en_domicilio','texto':'No en domicilio', 'numero':0});
            }
            if(avisos.no_finalizadas){
                this.avisosVisitas.push({'titulo':'no_finalizadas','texto':'No finalizadas', 'numero':avisos.no_finalizadas});
            }else{
                this.avisosVisitas.push({'titulo':'no_finalizadas','texto':'No finalizadas' ,'numero':0});
            }
            if(avisos.sin_confirmar){
                this.avisosVisitas.push({'titulo':'sin_confirmar','texto':'Sin confirmar', 'numero':avisos.sin_confirmar});
            }else{
                this.avisosVisitas.push({'titulo':'sin_confirmar','texto':'Sin confirmar', 'numero':0});
            }
            if(avisos.sin_firmar){
                this.avisosVisitas.push({'titulo':'sin_firmar','texto':'Sin firmar' ,'numero':avisos.sin_firmar});
            }else{
                this.avisosVisitas.push({'titulo':'sin_firmar','texto':'Sin firmar' ,'numero':0});
            }
            if(avisos.sin_fotos){
                this.avisosVisitas.push({'titulo':'sin_fotos', 'texto':'Sin fotos','numero':avisos.sin_fotos});
            }else{
                this.avisosVisitas.push({'titulo':'sin_fotos','texto':'Sin fotos' ,'numero':0});
            }
            console.log('avisosVisitas',this.avisosVisitas)
        },
       async cargarestadisticas(tramitador) {   
            const api = new PwgsApi;
            if(tramitador && tramitador!='todos'){
                this.datosestadisticas = await api.get('estadisticas/'+tramitador);
                console.log('tramits', this.datosestadisticas);
            }else{
                if(!tramitador){
                    this.datosestadisticas = await api.get('estadisticas');
                    this.avisosysatis();
                }else{
                    this.datosestadisticas = await api.get('estadisticas');
                }
            }
            console.log('estadsacadas',this.datosestadisticas);
            this.revisadas = this.datosestadisticas.contadores.total_visitas_revisadas;
            this.finalizados = this.datosestadisticas.contadores.total_finalizados;
            this.facturaciones = this.datosestadisticas.contadores.total_facturacion;
    },
    async comprobarFlota(){
        const api = new PwgsApi();
        const resp = await api.get('parametros-pwgs/gps_coche_webfleet');
        console.log('respui', resp);
        if(resp.gps_coche_webfleet == 1){
            this.flotaActivo = true;            
        }
    },
    async comprobarLlamadas(){
        const api = new PwgsApi();
        const resp = await api.get('parametros-pwgs/centralita_llamada');
        console.log('respui', resp);
        if(resp.centralita_llamada != ''){
            this.llamadaActivado = true;            
        }
    },
    toggle2(event) {
        this.$refs.info2.toggle(event);
    },

    },
    mounted() {
        this.comprobarFlota();
        this.comprobarLlamadas();
        setTimeout(() => {
            this.cargarestadisticas();
        }, 2000);
    },
    watch:{
        revisadas(value){
            console.log('cambiarevis', value);
        }
    }
})
</script>
<style>
.totales > div:first-child{
        background: -webkit-gradient(linear, left bottom, left top, color-stop(0, #00a65a), color-stop(1, #00ca6d));
    
}
.totales > div:nth-child(2){
    background: -webkit-gradient(linear, left bottom, left top, color-stop(0, #39cccc), color-stop(1, #7adddd));
}
.totales > div:last-child{
    background:  -webkit-gradient(linear, left bottom, left top, color-stop(0, #3c8dbc), color-stop(1, #67a8ce));
}
.totales > div{
    color: white;
    text-align: end;
    padding-right: 15px;
    border-radius: 3px;
}
</style>